import styled from 'styled-components';

const IndexStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & .HeroImage--caption {
        margin-top: -84px;

        @media screen and (min-width: 768px) {
            align-self: flex-start;
            margin: -178px 0 0 11.6%;
        }
    }

    & .pilot__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin-top: 45px;
        position: relative;
        &--text:first-child {
            margin-bottom: 75px;
        }

        &:after {
            content: '';
            border-width: 20px;
            border-color: ${(props) => props.theme.colors.secondary} transparent
                transparent;
            border-style: solid;
        }

        p {
            line-height: 2.625rem;
        }
    }

    & .pilot__greenAmerica {
        width: 100%;
        background-color: ${(props) => props.theme.colors.backgrounds.sand};
        @media screen and (max-width: 360px) {
            .section-header-copy,
            .eyebrow-text,
            .section-header {
                padding-left: 17px;
                padding-right: 17px;
            }
        }
        & > div {
            width: 100%;
            padding: 100px 0 66px 0;
            background-color: ${(props) =>
                props.theme.colors.backgrounds.white};

            @media screen and (min-width: 768px) {
                background-color: transparent;
                width: 60%;
            }
        }

        .stats {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 32px;

            @media screen and (min-width: 768px) {
                margin: 0 20px;
            }

            & > div {
                margin-bottom: 30px;

                @media screen and (min-width: 768px) {
                    margin-bottom: 40px;
                }
            }
        }
    }

    & .flex.flex--column--center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    & .pilot__locations {
        padding: 100px 0;
        @media screen and (max-width: 360px) {
            .section-header-copy,
            .eyebrow-text,
            .section-header {
                padding-left: 17px;
                padding-right: 17px;
            }
            > :last-child {
                padding-left: 17px;
                padding-right: 17px;
            }
        }
    }

    & .pilot__services {
        width: 100%;
        padding: 100px 0;
        background-color: ${(props) => props.theme.colors.backgrounds.sand};
        & > section {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            & > div {
                margin: 2rem 0;
                @media screen and (max-width: 768px) and (min-width: 375px) {
                    margin: 2rem 1rem;
                }
            }
        }
        a {
            font-family: ${(props) => props.theme.fonts[2]};
            font-size: 0.875rem;
            font-weight: bold;
            letter-spacing: 1.05px;
            line-height: 2.125rem;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.buttons.textLink};
        }
        @media screen and (max-width: 360px) {
            .section-header-copy,
            .eyebrow-text,
            .section-header {
                padding-left: 17px;
                padding-right: 17px;
            }
        }
    }

    .pilot__imageCopy {
        width: 100%;
        padding-top: 54px;
        background-color: ${(props) => props.theme.colors.backgrounds.sand};
    }

    .contact_background {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        & .pilot__services {
            padding: 6.25rem 0;
            position: relative;
            & .section-header-copy + div {
                position: absolute;
                bottom: calc((108 / 16) * 1rem);
            }
            & > section {
                width: 80%;
                flex-direction: row;
                justify-content: space-around;
                margin-top: 2rem;
                align-items: flex-start;
                max-width: 1100px;
            }
        }

        & .pilot__info {
            width: 55.2%;
            margin-top: 130px;
            margin-bottom: 80px;
        }

        & .pilot__locations {
            padding: 6.25rem 0;

            & .pilot__locationMap {
                margin: 1rem auto 0 auto;
                width: 100%;
                max-width: 924px;
            }
        }
    }

    .heroImage {
        background-image: url(${(props) => props.heroImageSrc?.url});
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 417px;

        @media screen and (min-width: 768px) {
            height: 100vh;
            max-height: 700px;
        }
    }
`;

export default IndexStyled;
