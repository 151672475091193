import React, { Component, useState, useEffect } from 'react';
import PropTypes, { nominalTypeHack } from 'prop-types';
import { withSize } from 'react-sizeme';
import Anchor from '../components/ui/Controls/Anchor';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image from '../components/ui/Image';
import HeadlineBox from '../components/HeadlineBox';
import IndexStyled from '../components/pageStyles/index-styles';
import ContentBox from '../components/ui/ContentBox';
import ContactCard from '../components/ContactCard';
import ImageWithCopy from '../components/ImageWithCopy';
import CircleImage from '../components/ui/CircleImage';
import GeneralContent from '../components/GeneralContent';
import MiniCard from '../components/MiniCard';
import useGlobal from '../store';

function IndexPage({ ...props }) {
    const { path, size } = props;
    const [globalState, globalActions] = useGlobal();
    const defaultSection = [{ main: {}, detail: [] }];
    const {
        home: {
            home_hero_image: [{ main: heroImageSection_main }] = defaultSection,
            home_capabilities: [
                {
                    main: capabilitiesSection_main,
                    detail: capabilitiesSection_detail,
                },
            ] = defaultSection,
            value_of_ethanol: [
                { main: valueEthanolSection_main },
            ] = defaultSection,
            safety_and_work: [
                { detail: safetySection_detail },
            ] = defaultSection,
        },
    } = globalState;

    const homeImageSrc =
        heroImageSection_main?.images &&
        heroImageSection_main?.images.length > 0 &&
        heroImageSection_main?.images[0]?.file;

    return (
        <Layout path={path}>
            <SEO title="Home" />
            <IndexStyled
                heroImageSrc={homeImageSrc}
                title={homeImageSrc?.title}
            >
                <div className="heroImage" />
                <HeadlineBox
                    elementClass="HeroImage--caption"
                    text={heroImageSection_main?.heading?.text}
                />
                <div className="pilot__info">
                    <ContentBox
                        hasHeadline={false}
                        bodyText={heroImageSection_main?.body?.text}
                        wrapperClass="pilot__info--text"
                    />
                </div>

                <div className="pilot__greenAmerica">
                    <GeneralContent
                        eyebrowText={capabilitiesSection_main?.heading?.text}
                        sectionHeaderCopy={capabilitiesSection_main?.body?.text}
                        generalContentStyles={{
                            textAlign: 'center',
                            eyebrow: {
                                textAlign: `center`,
                            },
                            header: {
                                textAlign: `${capabilitiesSection_main?.heading?.alignment}`,
                                mobile: {
                                    textAlign: 'center',
                                },
                            },
                            copy: {
                                textAlign: `${capabilitiesSection_main?.body?.alignment}`,
                                mobile: {
                                    textAlign: 'center',
                                },
                                marginBottom: '0px',
                            },
                        }}
                    />

                    <section className="flex flex--column--center stats">
                        {capabilitiesSection_detail &&
                            capabilitiesSection_detail.map((content, index) => {
                                return (
                                    <MiniCard
                                        key={content?._metadata}
                                        stat={content?.heading?.text}
                                        bgImage={content?.images[0].file}
                                        title={content?.body?.text}
                                    />
                                );
                            })}

                        <GeneralContent
                            sectionHeaderCopy={
                                capabilitiesSection_main?.body?.custom_data
                            }
                            generalContentStyles={{
                                textAlign: 'center',
                                eyebrow: {
                                    textAlign: `${capabilitiesSection_main?.body?.alignment}`,
                                    display: 'none',
                                },
                                header: {
                                    textAlign: `${capabilitiesSection_main?.body?.alignment}`,
                                    mobile: {
                                        textAlign: 'center',
                                    },
                                },
                                copy: {
                                    textAlign: `${capabilitiesSection_main?.body?.alignment}`,
                                    mobile: {
                                        textAlign: 'center',
                                        marginBottom: '30px',
                                    },
                                    marginBottom: '30px',
                                },
                            }}
                        />
                    </section>
                </div>

                <div className="pilot__locations">
                    <GeneralContent
                        eyebrowText={valueEthanolSection_main?.heading?.text}
                        sectionHeaderCopy={valueEthanolSection_main?.body?.text}
                        generalContentStyles={{
                            textAlign: 'center',
                            eyebrow: {
                                textAlign: `${valueEthanolSection_main?.heading?.alignment}`,
                            },
                            header: {
                                textAlign: 'center',
                                mobile: {
                                    textAlign: 'center',
                                },
                            },
                            copy: {
                                textAlign: `${valueEthanolSection_main?.body?.alignment}`,
                                mobile: {
                                    textAlign: 'center',
                                },
                            },
                        }}
                    />
                </div>
                <div className="pilot__imageCopy">
                    {safetySection_detail &&
                        safetySection_detail.map((content, index) => {
                            return (
                                <ImageWithCopy
                                    size={size}
                                    imageLeft={
                                        content?.images[0].alignment === 'Left'
                                            ? true
                                            : false
                                    }
                                    squareImage={true}
                                    copyTitle={content.heading.text}
                                    copyText={content.body.text}
                                    imgSrc={content?.images[0].file}
                                    key={content._metadata.uid}
                                    showDotGrid={index === 0 ? true : false}
                                />
                            );
                        })}
                </div>
                <ContactCard />
            </IndexStyled>
        </Layout>
    );
}
export default withSize()(IndexPage);
